import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import FiltroDeChips from "../../components/FiltroDeChips";
import Filtros from "../../components/Filtros";
import Titulo from "../../components/Titulo";
import EstilosContext from "../../contextos/EstilosContext";
import ObraContext from "../../contextos/ObraContext";
import OrdenesDeServicioContext from "../../contextos/OrdenesDeServicioContext";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import {
  chequearPermisos,
  desencriptar,
  encriptar,
} from "../../funciones/Funciones";
import PrimerPaso from "../general/PrimerPaso";
import ProgressBar from "../general/ProgressBar";
import ItemOrdenDeServicio from "./ItemOrdenDeServicio";
import ServidorContext from "../../contextos/ServidorContext";
// import {
//   crearTablaEnIndexedDB,
//   desencriptarConIndexedDB,
//   encriptarConIndexedDB,
//   existeTablaEnIndexedDB,
// } from "../../funciones/databaseIndexedDB";

const PageDashboardOrdenesDeServicio = (props) => {
  const [ordenes, setOrdenes] = useState([]);

  const [actualizarEnvioPorWP, setActualizarEnvioPorWP] = useState(0);
  const [mostrarProgressBar, setMostrarProgressBar] = useState(true);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [palabrasClaves, setPalabrasClaves] = useState([]);
  const [rolAdministrador, setRolAdministrador] = useState(false);

  const [mostrarBotonDescarga, setMostrarBotonDescarga] = useState(false);
  const [archPDF, setArchPDF] = useState("");

  // console.log("UND: ", localStorage.getItem("objFil"));

  // const [filtro, setFiltro] = useState(
  //   localStorage.getItem("objFil") != null
  //     ? desencriptar(localStorage.getItem("objFil"))
  //     : []
  // );

  const { servidor } = useContext(ServidorContext);

  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { obra, setObra } = useContext(ObraContext);
  const { estilos, isMobile, screenWidth } = useContext(EstilosContext);

  const {
    setOrdenDeServicioSeleccionada,
    todasLasOrdenes,
    setTodasLasOrdenes,
    filtro,
    setFiltro,
  } = useContext(OrdenesDeServicioContext);

  const { privilegios, setPrivilegios } = useContext(PrivilegioContext);

  // const [todasLasOrdenes, setTodasLasOrdenes] = useState(
  //   existeTablaEnIndexedDB("objTODS") ? desencriptarConIndexedDB("objTODS") : []
  // );

  const [mensajeWP, setMensajeWP] = useState({
    IDOrdenDeServicio: 0,
    Ubicacion: "",
    Prioridad: "",
    Descripcion: "",
    ClaveDeMensaje: "ENVIAR_ORDEN_DE_SERVICIO",
  });

  useEffect(() => {
    // let filtroAnterior = desencriptar(localStorage.getItem("objFil"));
    // setFiltro(filtroAnterior);
    // return () => {
    //   localStorage.removeItem("objFil");
    // };
  }, []);

  useEffect(() => {
    // console.log("ORD 2: ", orden);

    chequearPermisos(
      obra.Privilegios,
      "ORDENES_DE_SERVICIO_ROL_ADMINISTRADOR"
    ) === true
      ? setRolAdministrador(true)
      : setRolAdministrador(false);
  }, []);

  useEffect(() => {
    // console.log("Privilegios: ", JSON.stringify(privilegios));
    // let objobra = desencriptar(localStorage.getItem("objobra"));
    // console.log("obra: ", objobra);
    // setObra(objobra);

    // crearTablaEnIndexedDB("objTODS");

    traer_palabras_claves();
  }, []);

  useEffect(() => {
    props.setTitulo("Dashboard Ordenes de servicio");
    props.setPagina("altaOrdenDeServicio");
  }, [props.setTitulo]);

  useEffect(() => {
    localStorage.removeItem("objordserv");
    localStorage.removeItem("objods");

    setOrdenDeServicioSeleccionada({});

    // let lstord = localStorage.getItem("lstord");
    // console.log("lstord: ", lstord);
    // lstord ? setOrdenes(desencriptar(lstord)) : traer_ordenes_de_servicio();

    // console.log("lstOrdenesDeServicio: ", lstOrdenesDeServicio);
    // console.log("lstOrdenesDeServicio.length: ", lstOrdenesDeServicio.length);

    // if (lstOrdenesDeServicio.length === 0) {
    //   console.log("INICIO 2");

    // console.log("todas: ", todasLasOrdenes.length);

    if (todasLasOrdenes.length === 0) {
      // console.log("INICIO");
      setMostrarProgressBar(true);
      traer_ordenes_de_servicio();
    }
    // } else {
    //   console.log("menor o igual a cero.");
    // }
  }, []);

  useEffect(() => {
    // console.log("FIL: ", filtro);

    // if (!window.indexedDB) {
    //   console.log("IndexedDB no es soportado por tu navegador");
    // } else {
    //   console.log("IndexedDB es soportado por tu navegador");
    // }
    ordenar_ordenes_de_servicio();
  }, [filtro]);

  useEffect(() => {
    if (actualizarEnvioPorWP > 0) {
      // console.log("PASA POR ACA");
      // traer_ordenes_de_servicio();
      // console.log("ordenes: ", ordenes);

      setOrdenes((prevState) => {
        return prevState.map((item) => {
          item.Destinatarios.forEach((dest) => {
            if (dest.IDOrdenDeServicioDestinatario === actualizarEnvioPorWP) {
              dest.CantidadEnviosPorWhatsapp = (
                parseInt(dest.CantidadEnviosPorWhatsapp) + 1
              ).toString();
            }
          });
          return item;
        });
      });
    }
  }, [actualizarEnvioPorWP]);

  useEffect(() => {
    ordenar_ordenes_de_servicio();
  }, [todasLasOrdenes]);

  // useEffect(() => {
  //   console.log("mostrarProgressBar: ", mostrarProgressBar);
  //   // setOrdenes(lstOrdenesDeServicio);
  // }, [mostrarProgressBar]);

  // useEffect(() => {
  //   setMostrarProgressBar(false);
  // }, [ordenes]);

  const ordenar_ordenes_de_servicio = () => {
    // console.log("FILTRO: ", filtro);
    // console.log("todasLasOrdenes: ", todasLasOrdenes);

    let ultimoFiltro = localStorage.getItem("objFil");

    // console.log("ultimoFiltro: ", desencriptar(ultimoFiltro));

    let filtroNuevo;

    // if (ultimoFiltro) {
    //   console.log("PASA POR ULTIMO FILTRO");

    //   filtroNuevo = desencriptar(ultimoFiltro);
    // } else {
    filtroNuevo = filtro;
    // }

    // ORDENAMIENTO POR PRIORIDAD
    const prioridades = {
      ALTA: 3,
      MEDIA: 2,
      BAJA: 1,
    };

    function compararPrioridades(a, b) {
      return prioridades[b.Prioridad] - prioridades[a.Prioridad];
    }
    // ORDENAMIENTO POR PRIORIDAD

    // ORDENAMIENTO POR ESTADO
    const estados = { PENDIENTE: 2, FINALIZADO: 1 };

    function compararEstados(a, b) {
      return estados[b.EstadoDeLaOrden] - estados[a.EstadoDeLaOrden];
    }

    // ORDENAMIENTO POR ESTADO
    let ordenesFiltradas = [];

    // console.log("to: ", todasLasOrdenes);

    if (filtroNuevo.length === 0) {
      ordenesFiltradas = todasLasOrdenes;
    } else {
      ordenesFiltradas = todasLasOrdenes.filter((orden) => {
        let ubicacionCoincide = false;
        let palabraClaveCoincide = false;

        if (Array.isArray(orden.Ubicacion)) {
          ubicacionCoincide = orden.Ubicacion.some((ubicacion) =>
            filtroNuevo.some(
              (filtroItem) =>
                filtroItem.PalabraClave === ubicacion.PalabraClave &&
                filtroItem.EsUbicacion === "1"
            )
          );
        }

        if (Array.isArray(orden.PalabrasClaves)) {
          palabraClaveCoincide = orden.PalabrasClaves.some((palabraClave) =>
            filtroNuevo.some(
              (filtroItem) =>
                filtroItem.PalabraClave === palabraClave.PalabraClave &&
                filtroItem.EsUbicacion === "0"
            )
          );
        }

        return (
          (filtroNuevo.filter((item) => item.EsUbicacion === "1").length ===
            0 ||
            ubicacionCoincide) &&
          (filtroNuevo.filter((item) => item.EsUbicacion === "0").length ===
            0 ||
            palabraClaveCoincide)
        );
      });
    }

    // console.log("filtradas: ", ordenesFiltradas);

    let arrOrdenes = ordenesFiltradas.filter(
      (order) =>
        order.Destinatarios &&
        (obra.EsAdmin == 1 ||
          rolAdministrador ||
          order.Destinatarios.some(
            (destinatario) =>
              destinatario.IDUsuarioDestinatario === usuario.IDUsuario
          ))
    );
    arrOrdenes.sort(compararPrioridades);

    arrOrdenes.sort(compararEstados);

    // console.log("ordenesFiltradas: ", ordenesFiltradas);
    // console.log("arrOrdenes: ", arrOrdenes);
    // console.log("filtro: ", filtro);

    setOrdenes(arrOrdenes);

    // exportar_pdf();

    // setLstOrdenesDeServicio(arrOrdenes);

    // localStorage.setItem("lstord", encriptar(arrOrdenes));
  };

  useEffect(() => {
    // console.log("PASA POR 1: ", lstOrdenesDeServicio.length);

    if (ordenes.length > 0) {
      setMostrarProgressBar(false);
      // console.log("PASA");
      // console.log(JSON.stringify(lstOrdenesDeServicio));
    }
  }, [ordenes]);

  // useEffect(() => {
  //   console.log("ACTUALIZA ORDENES: ", ordenes);
  // }, [ordenes]);

  // useEffect(() => {
  //   console.log("PASA");
  //   const tarea = {
  //     obra: obra.Obra,
  //     // etiquetas: ordenesDeServicio.etiquetas,
  //     prioridad: "ALTAA",
  //   };

  //   // const detalle = `${tarea.obra} - ${tarea.etiquetas} | ${tarea.prioridad}`;
  //   const detalle = `${tarea.obra} | PRIORIDAD ${tarea.prioridad}`;

  //   if (isMobile && window.ReactNativeWebView) {
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({
  //         accion: "notification",
  //         contenido: {
  //           notificacion: {
  //             titulo: "Tarea asignada",
  //             body: detalle,
  //             // destinatarios: "ExponentPushToken[hyKGf6Kp07_wHrAG-auusr]",
  //             destinatarios: "ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]",
  //             link: {
  //               seccion: "ORDENES_DE_SERVICIO",
  //               objeto: { IDOrdenDeServicio: 112 },
  //               goTo: "detalleOrdenDeServicio",
  //             },
  //           },
  //         },
  //       })
  //     );
  //   }
  // }, []);

  const tokenValido = async (props) => {
    try {
      const API_ENDPOINT = servidor + `api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      let objusu = desencriptar(localStorage.getItem("objusu"));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "CHEQUEAR_TOKEN_VALIDO",
          tok1: objusu.Tok1,
          tok2: objusu.Tok2,
          // tok1: localStorage.getItem("tok1"),
          // tok2: localStorage.getItem("tok2"),
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const usuarios = await datos.json();

      // console.log(usuarios.result.usuario);

      if (usuarios.result.usuario != undefined) {
        // signIn(usuarios.result.usuario.email, usuarios.result.usuario.clave);

        props.setUsuario(usuarios.result.usuario[0]);

        // localStorage.setItem("tok1", usuarios.result.usuario[0].Tok1);
        // localStorage.setItem("tok2", usuarios.result.usuario[0].Tok2);
        // localStorage.setItem("fechaTok", usuarios.result.usuario[0].FechaTok);

        // console.log(usuarios.result.usuario[0]);

        // alert(usuario.Logueado);
        // navigate("/dashboard");
      } else {
        console.log("Token inválido.");
      }

      // return usuarios.result;
      return true;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const traer_ordenes_de_servicio = async () => {
    try {
      const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

      setMostrarProgressBar(true);

      // console.log("ENTRA A TRAER ODS");

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      // let objusu = desencriptar(localStorage.getItem("objusu"));

      // console.log("IDObra: ", obra.IDObra);

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "TRAER_ORDENES_DE_SERVICIO",
          idObra: obra.IDObra,
          // tok1: localStorage.getItem("tok1"),
          // tok2: localStorage.getItem("tok2"),
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const respuesta = await datos.json();

      // console.log("ordenes NEW: ", respuesta.result.ordenes);

      if (respuesta.result.ordenes) {
        setTodasLasOrdenes(respuesta.result.ordenes);
        // setLstOrdenesDeServicio(respuesta.result.ordenes);
        setOrdenes(respuesta.result.ordenes);

        // localStorage.setItem("objTODS", encriptar(respuesta.result.ordenes));

        // if (!window.indexedDB) {
        //   console.log("IndexedDB no es soportado por tu navegador");
        // } else {
        //   console.log("IndexedDB es soportado por tu navegador");

        //   if (navigator.storage && navigator.storage.estimate) {
        //     navigator.storage.estimate().then(({ usage, quota }) => {
        //       console.log(`Uso de bytes: ${usage}`);
        //       console.log(`Límite de bytes: ${quota}`);
        //     });
        //   } else {
        //     console.log(
        //       "La API de almacenamiento no es soportada por tu navegador"
        //     );
        //   }

        //   encriptarConIndexedDB(
        //     "objTODS",
        //     JSON.stringify(respuesta.result.ordenes)
        //   );
        // }
      } else {
        setOrdenes([]);
        setMostrarProgressBar(false);
        // encriptarConIndexedDB("objTODS", JSON.stringify([]));
      }

      // return usuarios.result;
      // return true;
    } catch (error) {
      console.error(error);
      setMostrarProgressBar(false);
    }

    return "ERROR";
  };

  const traer_palabras_claves = async () => {
    try {
      const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

      // setMostrarProgressBar(true);

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "TRAER_UBICACIONES_Y_PALABRAS_CLAVES",
          idObra: obra.IDObra,
          // esUbicacion: "NO",
          // tok1: localStorage.getItem("tok1"),
          // tok2: localStorage.getItem("tok2"),
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const respuesta = await datos.json();

      // console.log("palabrasClaves: ", respuesta.result.palabrasClaves);

      if (respuesta.result.ubicaciones) {
        setUbicaciones(respuesta.result.ubicaciones);
        // console.log("ubicaciones: ", respuesta.result.ubicaciones);

        // setOrdenes(respuesta.result.ordenes);
      } else {
        setUbicaciones([]);
      }

      if (respuesta.result.palabrasClaves) {
        setPalabrasClaves(respuesta.result.palabrasClaves);
        // console.log("palabrasClaves: ", respuesta.result.palabrasClaves);

        // setOrdenes(respuesta.result.ordenes);
      } else {
        setPalabrasClaves([]);
      }

      // setMostrarProgressBar(false);

      // return usuarios.result;
      return true;
    } catch (error) {
      console.error(error);
      // setMostrarProgressBar(false);
    }

    return "ERROR";
  };

  // const exportar_pdf = async () => {
  //   // console.log(texto);
  //   // console.log("listo presupuesto");

  //   setMostrarProgressBar(true);

  //   const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "GENERAR_EL_PDF_DE_ORDENES_DE_SERVICIO",
  //       idObra: obra.IDObra,
  //       ordenes: ordenes,
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log(respuesta);

  //   if (respuesta.result.archPDF) {
  //     // console.log("archExcel: " + respuesta.result.archExcel);
  //     //   setTexto("");

  //     setArchPDF(servidor + respuesta.result.archPDF);
  //     setMostrarBotonDescarga(true);
  //   } else {
  //     setMostrarBotonDescarga(false);
  //     setArchPDF("");
  //     console.log("NO TRAE NADA EN exportar_pdf()");
  //     // setState([]);
  //   }

  //   setMostrarProgressBar(false);
  // };

  // console.log("screenWidth: ", screenWidth);
  return (
    // <div style={{ border: "1px solid blue" }}>

    <>
      {/* {console.log("mostrarProgressBar: ", mostrarProgressBar)} */}
      {usuario.Logueado === "SI" || tokenValido() === true ? (
        mostrarProgressBar ? (
          <ProgressBar mostrar={mostrarProgressBar} />
        ) : (
          <Grid
            container
            // sm={12}
            // spacing={2}
            // style={{ border: "1px solid #ef1414" }}
          >
            {palabrasClaves.length > 0 ? (
              <div
                sm={12}
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: "#cccccc1f",
                  margin: "10px",
                  padding: "4px",
                  display: "flex",
                  justifyContent:
                    screenWidth === "xs" || screenWidth === "sm"
                      ? "center"
                      : "left",
                  alignItems: "center",
                }}
              >
                {/* <Filtros
                  setFiltro={setFiltro}
                  filtro={filtro}
                  data={ubicaciones}
                /> */}
                <Filtros
                  // setFiltro={setFiltro}
                  // filtro={filtro}
                  data={palabrasClaves}
                />

                <Typography variant="h6">
                  <Button href={archPDF}>Exportar a Excel</Button>
                </Typography>
              </div>
            ) : (
              // <FiltroDeChips
              //   data={palabrasClaves}
              //   setFiltro={setFiltro}
              //   filtro={filtro}
              // />

              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                sm={12}
                // style={{ border: "1px solid #FF0000" }}
              >
                <PrimerPaso
                  texto="CREA TU PRIMERA ORDEN DE SERVICIO"
                  link="/altaOrdenDeServicio"
                />
              </Grid>
            )}

            <Grid item sm={12}>
              {`Cantidad: ${ordenes.length}`}
            </Grid>

            {ordenes.length > 0
              ? ordenes.map((orden, index) => (
                  <>
                    <ItemOrdenDeServicio
                      key={index}
                      setActualizarEnvioPorWP={setActualizarEnvioPorWP}
                      orden={orden}
                      index={index}
                      mensajeWP={{
                        IDOrdenDeServicio: orden.IDOrdenDeServicio,
                        Ubicacion:
                          orden.Ubicacion.length > 0
                            ? orden.Ubicacion[0].PalabraClave
                            : "",
                        Prioridad: orden.Prioridad,
                        Descripcion: orden.Descripcion,
                        ClaveDeMensaje: "ENVIAR_ORDEN_DE_SERVICIO",
                      }}
                    />
                  </>
                ))
              : ""}
          </Grid>
        )
      ) : (
        ""
      )}
    </>

    // </div>
  );
};

export default PageDashboardOrdenesDeServicio;
