import { Dashboard } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import DashboardContext from "../../contextos/DashboardContext";
import ObraContext from "../../contextos/ObraContext";
import { desencriptar, formatearFecha } from "../../funciones/Funciones";
import BoxContador from "../general/BoxContador";
import PrimerPaso from "../general/PrimerPaso";
import TablaGeneral from "./TablaGeneral";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

const PageDashboardVolquetes = (props) => {
  let navigate = useNavigate();

  const { dashboard, setDashboard } = useContext(DashboardContext);
  const { obra, setObra } = useContext(ObraContext);

  const [volquetes, setVolquetes] = useState([]);

  const [dashboardTotales, setDashboardTotales] = useState("");

  const [mostrarProgressBar, setMostrarProgressBar] = useState(false);

  // const initData = [
  //   { ID: 1, Fecha: "03/07/2023", Cantidad: 1 },
  //   { ID: 2, Fecha: "04/07/2023", Cantidad: 2 },
  //   { ID: 3, Fecha: "05/07/2023", Cantidad: 1 },
  // ];

  const [data, setData] = useState([{ id: "", fecha: "", cantidad: "" }]);
  const [headerNames, setHeaderNames] = useState("");

  useEffect(() => {
    props.setTitulo("Administración de volquetes");
    props.setPagina("altaVolquete");
  }, [props.setTitulo]);

  useEffect(() => {
    // console.log(dashboard);

    let dashTotales;
    let dashPendientes;
    let dashConfirmados;

    let tablaVolquetes;

    // console.log("dashboard.listaVolquetes: ", dashboard.listaVolquetes);

    // if (dashboard.listaVolquetes == undefined) {
    let objdashboard = desencriptar(localStorage.getItem("objdashboard"));

    setDashboard(objdashboard);

    dashTotales = objdashboard.listaVolquetes.filter(
      (obr) => obr.IDObra == obra.IDObra
    );

    // console.log("dashTotales: ", dashTotales);

    // } else {
    //   console.log("DBOARD: ", dashboard);
    //   dashTotales = dashboard.listaVolquetes.filter(
    //     (obr) => obr.IDObra == obra.IDObra
    //   );
    // }

    setDashboardTotales(dashTotales);

    // tablaVolquetes.map((item)=>
    //    {ID: item.IDPedidoDeVolquete, FechaProgramada: item.FechaProgramada, HoraProgramada: item.HoraProgramada, Cantidad. item.Cantidad}
    // )
    // console.log("dashTotales[0]: ", dashTotales[0]);
    // const headers = Object.keys(dashTotales[0]);

    const hNames = {
      IDPedidoDeVolquete: "ID",
      FechaProgramada: "Fecha",
      HoraProgramada: "Hora",
      Cantidad: "Cantidad",
      Confirmacion: "Confirmación",
      Recibido: "Recibido",
    };

    setHeaderNames(hNames);

    const filtrarCampos = (data, campos) => {
      return data.map((item) => {
        const newItem = {};
        campos.forEach((campo) => {
          campo === "FechaProgramada"
            ? (newItem[campo] = formatearFecha(item[campo], "dd/MM/yyyy"))
            : (newItem[campo] = item[campo]);
          // newItem[campo] = item[campo];
        });
        return newItem;
      });
    };

    const campos = [
      "IDPedidoDeVolquete",
      "Cantidad",
      "FechaProgramada",
      "HoraProgramada",
      // "Confirmacion",
      // "Recibido",
    ];
    const dataFiltrada = filtrarCampos(dashTotales, campos);

    // dataFiltrada.sort((a, b) => {
    //   // new Date(dateString.replace(" ", "T") + "-03:00");
    //   const dateA = new Date(
    //     a.FechaProgramada + "T" + a.HoraProgramada + "-03:00"
    //   );
    //   const dateB = new Date(
    //     b.FechaProgramada + "T" + b.HoraProgramada + "-03:00"
    //   );
    //   return dateB - dateA;
    // });

    dayjs.extend(utc);
    dayjs.extend(customParseFormat);

    dataFiltrada.sort((a, b) => {
      const dateA = dayjs.utc(
        `${a.FechaProgramada} ${a.HoraProgramada}`,
        "DD/MM/YYYY HH:mm"
      );
      const dateB = dayjs.utc(
        `${b.FechaProgramada} ${b.HoraProgramada}`,
        "DD/MM/YYYY HH:mm"
      );

      return dateA.valueOf() - dateB.valueOf();
    });

    setData(dataFiltrada);
    // console.log("dashTotales: ", dashTotales);

    dashPendientes = dashTotales.filter((obr) => obr.Recibido == 0);
    dashConfirmados = dashTotales.filter((obr) => obr.Confirmacion == 1);

    let cantVolquetesTotales = 0;
    let cantVolquetesPendientes = 0;
    let cantVolquetesConfirmados = 0;

    dashTotales.map((vol) => {
      cantVolquetesTotales =
        parseInt(cantVolquetesTotales) + parseInt(vol.Cantidad);
    });

    dashPendientes.map((vol) => {
      cantVolquetesPendientes =
        parseInt(cantVolquetesPendientes) + parseInt(vol.Cantidad);
    });

    dashConfirmados.map((vol) => {
      cantVolquetesConfirmados =
        parseInt(cantVolquetesConfirmados) + parseInt(vol.Cantidad);
    });

    // console.log(dashTotales);

    setVolquetes(...volquetes, {
      cantidadVolquetesTotales: cantVolquetesTotales,
      cantidadVolquetesPendientes: cantVolquetesPendientes,
      cantidadVolquetesConfirmados: cantVolquetesConfirmados,
    });
  }, []);

  // useEffect(() => {
  //   console.log(volquetes);
  // }, [volquetes]);

  const handleAltaVolquete = () => {
    navigate("/altaVolquete");
  };

  // const initData = {
  //   encabezados: [
  //     { titulo: "ID", align: "left" },
  //     { titulo: "FECHA", align: "left" },
  //     { titulo: "CANTIDAD", align: "center" },
  //   ],
  //   filas: [
  //     { id: 1, campo: ["03/07/2023", "1"], align: "left" },
  //     { id: 2, campo: ["04/07/2023", "1"], align: "left" },
  //     {
  //       id: 3,
  //       campo: ["05/07/2023", "2"],
  //       align: "center",
  //     },
  //   ],
  // };

  return (
    <>
      {}
      <Grid
        container
        spacing={2}
        marginTop={5}
        paddingLeft={4}
        paddingRight={2}
      >
        {dashboardTotales.length > 0 ? (
          <>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
              }}
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <BoxContador
                titulo="Totales"
                imagen="volquete.png"
                cantidad={volquetes.cantidadVolquetesTotales}
              />
              {/* <BoxContador
                titulo="Confirmados"
                imagen="volquete.png"
                cantidad={volquetes.cantidadVolquetesConfirmados}
              />
              <BoxContador
                titulo="Pendientes"
                imagen="volquete.png"
                cantidad={volquetes.cantidadVolquetesPendientes}
              /> */}
            </Grid>
            <Grid item sm={12}>
              &nbsp;
            </Grid>

            <TablaGeneral
              data={data}
              headerNames={headerNames}
              columns={["Nombre", "Edad"]}
            />
          </>
        ) : (
          <Grid
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <PrimerPaso texto="PEDÍ TU PRIMER VOLQUETE" link="/altaVolquete" />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PageDashboardVolquetes;
