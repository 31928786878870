import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ServidorContext from "../../contextos/ServidorContext";
import MensajesContext from "../../contextos/MensajesContext";
import ObraContext from "../../contextos/ObraContext";
import ConfigContext from "../../contextos/ConfigContext";
import BoxPresupuestos from "./papers/BoxPresupuestos";

const GeneralConfig = () => {
  const { servidor } = useContext(ServidorContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const { obra } = useContext(ObraContext);

  const { config, setConfig } = useContext(ConfigContext);

  // const initConfig = {
  //   presupuestosBeneficioMO: "",
  //   presupuestosBeneficioMateriales: "",
  //   presupuestosCheckMostrarDetalle: 0,
  // };
  // const [modifConfig, setModifConfig] = useState(initConfig);

  // useEffect(() => {
  //   // if (modifConfig[0].campo !== "") {
  //   //   modificar_configuracion();
  //   // }

  //   console.log("modifConfig: ", modifConfig);
  // }, [modifConfig]);

  // const handleCheck = async (event, item) => {
  //   // console.log(item);

  //   // console.log("IDObra: " + obra.IDObra);
  //   // console.log("IDSubcontrato: " + modificar.IDSubcontrato);
  //   // console.log("IDDocumentacion: " + item.IDDocumentacion);
  //   // console.log("checked: " + event.target.checked);

  //   if (item === "PRESUPUESTOS_MOSTRAR_BENEFICIO") {
  //     setModifConfig([
  //       {
  //         ...modifConfig,
  //         presupuestosCheckMostrarDetalle: event.target.checked,
  //       },
  //     ]);
  //   }
  // };

  const modificar_configuracion = async () => {
    const API_ENDPOINT = servidor + `api_config.php`;

    // console.log({
    //   operacion: "ACTUALIZAR_CONFIGURACION",
    //   idObra: obra.IDObra,
    //   presupuestosBeneficioMO: config.PresupuestosBeneficioMO,
    //   presupuestosBeneficioMateriales: config.PresupuestosBeneficioMateriales,
    //   presupuestosCheckMostrarDetalle: config.PresupuestosCheckMostrarDetalle,

    //   // campo: modifConfig.campo,
    //   // valor: modifConfig.valor,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ACTUALIZAR_CONFIGURACION",
        idObra: obra.IDObra,
        presupuestosBeneficioMO: config.PresupuestosBeneficioMO,
        presupuestosBeneficioMateriales: config.PresupuestosBeneficioMateriales,
        presupuestosCheckMostrarDetalle: config.PresupuestosCheckMostrarDetalle,

        // campo: modifConfig.campo,
        // valor: modifConfig.valor,
      }),
    });
    const respuesta = await datos.json();

    if (respuesta.result.resultado === "OK") {
      // console.log(respuesta.result.asignaciones);
      // setModifConfig(initConfig);

      setMensaje({
        text: "Configuración modificada con éxtio!",
        tipo: "success",
      });

      setMostrarMensaje(true);
    } else {
      setMensaje({
        text: "Error al modificar la configuración!",
        tipo: "error",
      });

      setMostrarMensaje(true);
      console.log("NO TRAE NADA EN: modificar_configuracion()");
      // setState([]);
    }
  };

  const handleSubmit = () => {
    // console.log("guardar");

    modificar_configuracion();
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,

            width: 600,
            height: "auto",
          },
        }}
      >
        <Paper elevation={3}>
          <BoxPresupuestos pagina="GENERAL_CONFIG" />
        </Paper>
      </Box>

      <Box p={1} border={0} width={600}>
        <Grid
          container
          // border={1}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid
            item
            // border={1}
            style={{
              /*paddingBottom: "12px"*/
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button onClick={handleSubmit}>Confirmar</Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default GeneralConfig;
